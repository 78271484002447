import React, { useEffect, useState, useContext } from 'react';
import { SocialIcon } from 'react-social-icons';
import { ThemeContext } from 'styled-components';
import endpoints from '../constants/endpoints';

const styles = {
  iconStyle: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
};

function Social() {
  const theme = useContext(ThemeContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(endpoints.social, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  return (
    <div className="social">
      <a href="https://maps.app.goo.gl/duse1NvYKjTRUaLG7" target="_blank" rel="noopener noreferrer">
      Dumlupınar Mahallesi, 2571. Sokak, No: 1, İç Kapı: 1, Merkez, Osmaniye
      </a>
    </div>
  );
}

export default Social;
