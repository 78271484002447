import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal';
import endpoints from '../constants/endpoints';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';

const styles = {
  nameStyle: {
    fontSize: '5em',
  },
  inlineChild: {
    display: 'flex',
    width: '50%',
    marginTop: '40px',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(endpoints.home, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  return data ? (
    <Fade>
      <div style={styles.mainContainer}>
        <h1 style={styles.nameStyle}>{data?.name}</h1>
        <div style={{ flexDirection: 'column', display: "flex", alignItems: "center" }}>
          <h2>Su Tesisatı İşleri</h2>
          <p style={styles.inlineChild}>Tesisat konusundaki uzmanlığımız ve deneyimimizle, müşterilerimize yüksek kaliteli tesisat hizmetleri sunuyoruz. Yılların verdiği bilgi birikimiyle, her türlü tesisat sorununu çözme konusunda güvendiğiniz bir partner olmaktan gurur duyuyoruz. Müşteri memnuniyeti ve güvenilirlik ilkelerimiz doğrultusunda, işlerimizi dikkatli bir şekilde yaparız ve her projeyi zamanında tamamlarız. Modern tesisat teknolojilerini takip ederek, en son yenilikleri ve en iyi uygulamaları kullanarak, tesisat ihtiyaçlarınıza çözüm sunuyoruz.

Hizmetlerimiz arasında su tesisatı kurulumu, tamiri, sızıntı onarımları, ısıtma sistemi montajı, kombi bakımı ve daha fazlası bulunmaktadır. Ekipmanlarımız kaliteli ve dayanıklıdır, böylece uzun ömürlü çözümler sunarız. Acil durumlar için 7/24 hizmet veriyoruz ve tesisat sorunlarınızı hızla çözmek için buradayız.

Sizin için en iyi tesisat çözümlerini sunmak için buradayız. Bizimle iletişime geçin ve tesisat ihtiyaçlarınıza profesyonel bir yaklaşım ve mükemmel hizmet ile çözüm sunmamıza izin verin.</p>
        </div>
        <Social />
      </div>
    </Fade>
  ) : <FallbackSpinner />;
}

export default Home;
